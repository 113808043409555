<template>
    <BT-Blade-Items
        bladeName="customer-orders-import"
        canDelete
        :canRefresh="false"
        :canSearch="false"
        canSearchLocal
        :dependantBlades="[{ bladeName: 'customer-order' }]"
        flexColumn
        :headers="[
            { text: 'INV#', value: 'invoiceNumber', subtitle: 2, searchable: true },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', subtitle: 1, prefix: 'Due: ' },
            { text: 'Customer', value: 'supplyAgreement', display: true, searchable: true },
            { text: 'Destination', value: 'location', textFilter: 'toLocationLine' }]"
        :hide-footer="false"
        :items="orders"
        :loading="isLoading"
        :searchProps="['invoiceNumber', 'contactName']"
        :showFilters="false"
        title="Import Customer Orders">
        <template v-slot:actions>
            <BT-File-Select @fileSelected="importCSV" text="Import 1.0 CSV" title="Import CSV from BlitzIt 1.0" />
            <BT-Snack v-model="msg" />
            <v-spacer />
            <v-btn small @click="saveAllOrders">
                Save All
            </v-btn>
        </template>
        <template v-slot:supplyAgreement="{ item }">
            {{ item.supplyAgreement != null ? item.supplyAgreement.buyer.companyName : 'Unknown' }}
        </template>
        <template v-slot:itemActions="{ item, items }">
            <v-btn
                v-if="!item.isSaved"
                @click.stop="saveCustomerOrder(item, items)"
                icon
                :loading="item.loadingCount > 0"
                small
                title="Save">
                <v-icon small>mdi-content-save</v-icon>
            </v-btn>
            <v-icon v-else small>mdi-check</v-icon>
        </template>
    </BT-Blade-Items>
</template>

<script>
import { compareString } from '~helpers';
import { firstBy } from 'thenby';
import { DateTime } from 'luxon/src/luxon';

export default {

    name: 'Customer-Orders-New-Blade',
    components: {
        BTFileSelect: () => import('~components/BT-File-Select.vue'),
    },
    data: function() {
        return {
            customers: [],
            products: [],
            mappings: [],
            isLoading: false,
            msg: null,
            orders: []
        }
    },
    methods: {
       async importCSV(file) {
            var reader = new FileReader();
            
            reader.onload = async (e) => {
                this.isLoading = true;
                var txt = e.target.result;
                if (txt.length) {
                    var arrList = txt
                        .split(/\r?\n/)
                        .filter(z => z)
                        .map(z => z.split(','));
                    
                    var headers = arrList[0].map(z => z.replaceAll('*','').replaceAll(' ', ''));
                    
                    var objList = arrList.map(arrOrder => {
                        var newObj = {};
                        headers.forEach((header, ind) => {
                            newObj[header] = arrOrder[ind];
                        });

                        return newObj;
                    })

                    objList.splice(0, 1);

                    this.customers = await this.$BlitzIt.store.getAll('customers', { properties: 'Buyer,ID' });
                    this.mappings = await this.$BlitzIt.store.getAll('order-invoice-mappings');
                    this.products = await this.$BlitzIt.store.getAll('products', { filterBy: 'Sold' });

                    var orderList = [];
                    var missedLines = [];

                    console.log(objList);

                    for (let ind = 0; ind < objList.length; ind++) {
                        const obj = objList[ind];
                        
                        var existingOrder = orderList.find(z => compareString(z.contactName) == compareString(obj.ContactName) && z.invoiceNumber == obj.InvoiceNumber);

                        if (existingOrder == null) {
                            //create new
                            existingOrder = {
                                contactName: obj.ContactName, //for csv matching
                                invoiceNumber: obj.InvoiceNumber, //for csv matching
                                supplyAgreementID: null,
                                supplyAgreement: null,
                                locationID: null,
                                location: null,
                                possibleLocations: [],
                                dueDate: null,
                                isBuyerCourier: false,
                                orderItems: [],
                                isSaved: false
                            }

                            orderList.push(existingOrder);
                        }

                        //hardwired exception
                        if (obj.InventoryItemCode == 'M9M9') {
                            obj.InventoryItemCode = 'M2M2';
                            obj.Quantity = Number.parseFloat(obj.Quantity) * 9;
                        }
                        if (obj.InventoryItemCode == 'L9L9') {
                            obj.InventoryItemCode = 'L2L2';
                            obj.Quantity = Number.parseFloat(obj.Quantity) * 9;
                        }

                        //find product
                        var prod = this.products.find(z => compareString(z.abbreviation) == compareString(obj.InventoryItemCode) || compareString(z.productName) == compareString(obj.Description));
                        if (prod == null) {
                            //look for mappings
                            var mapping = this.mappings.find(z => z.itemType == 'Product' && z.externalValue == obj.InventoryItemCode);
                            if (mapping != null) {
                                prod = this.products.find(z => z.id == this.mappings.itemID);
                            }
                        }

                        if (prod == null) {
                            missedLines.push(obj);
                        }
                        else {
                            existingOrder.orderItems.push({
                                lineItemCode: null,
                                productID: prod.id,
                                product: prod,
                                description: prod.productName,
                                quantity: Number.parseFloat(obj.Quantity),
                                unitPrice: Number.parseFloat(obj.UnitAmount),
                                discount: 0,
                                tags: null,
                                taxType: null,
                            })
                        }

                        if (existingOrder.supplyAgreementID == null) {
                            //find supply agreement to match
                            var existingCustomer = this.customers.find(x => compareString(x.buyer.companyName) == compareString(obj.ContactName));
                            if (existingCustomer != null) {
                                existingOrder.supplyAgreement = existingCustomer;
                                existingOrder.supplyAgreementID = existingCustomer.id;

                                var policy = await this.$BlitzIt.store.get('suppliers', existingCustomer.id, null, false, null, '/Locations/Locations');
                                existingOrder.possibleLocations = policy.possibleLocations;
                                if (existingOrder.possibleLocations.length == 1) {
                                    existingOrder.location = existingOrder.possibleLocations[0];
                                    existingOrder.locationID = existingOrder.location.id;
                                }
                            }
                        }
                        
                        if (existingOrder.dueDate == null) {
                            var rDate = DateTime.fromFormat(obj.InvoiceDate, 'd/LL/yyyy h:mm:ss a');
                            existingOrder.dueDate = rDate.toUTC().toString();
                        }
                    }

                    orderList.forEach(order => {
                        if (!this.orders.some(z => z.contactName == order.contactName && z.invoiceNumber == order.invoiceNumber)) {
                            this.orders.push(order);
                        }
                    })

                    this.orders.sort(firstBy(z => z.supplyAgreementID != null).thenBy(z => z.supplyAgreement != null ? z.supplyAgreement.buyer.companyName : 'aaa'));

                    this.msg = missedLines.length + ' Lines Missed.  ' + missedLines.map(z => z.InventoryItemCode).toString();
                    console.log(this.msg);

                }

                this.isLoading = false;
            };

            reader.readAsText(file);

        },
        async saveAllOrders() {
            this.isLoading = true;
            for (let i = 0; i < this.orders.length; i++) {
                const order = this.orders[i];
                if (!order.isSaved && order.supplyAgreementID != null) {
                    await this.saveCustomerOrder(order, this.orders);
                }
            }
            this.isLoading = false;
        },
        async saveCustomerOrder(item) {
            try {
                item.loadingCount += 1;
                console.log(item);
                if (item.supplyAgreementID == null) {
                    var c = await this.$selectItem({ 
                        navigation: 'customers', 
                        itemText: 'buyer.companyName', 
                        label: item.contactName, 
                        params: { properties: 'Buyer,ID' } 
                    });

                    if (c != null) {
                        item.supplyAgreementID = c.id;
                        item.supplyAgreement = c;

                        var policy = await this.$BlitzIt.store.get('suppliers', c.id, null, false, null, '/Locations/Locations');
                        item.possibleLocations = policy.possibleLocations;
                        if (item.possibleLocations.length > 1) {
                            item.location = await this.$selectItemFrom({ 
                                items: item.possibleLocations, 
                                textFilter: 'toLocationLine', 
                                required: true, 
                                label: item.contactName + ' - Destination' });
                                
                            if (item.location != null) {
                                item.locationID = item.location.id;
                            }
                        }
                        else if (item.possibleLocations.length == 1) {
                            item.location = item.possibleLocations[0];
                            item.locationID = item.location.id;
                        }
                        else {
                            console.log('no possible locations found');
                        }
                    }
                    else {
                        console.log('no policy found');
                    }
                }

                if (item.supplyAgreementID != null && item.locationID != null) {
                    await this.$BlitzIt.store.post('customer-orders', item);
                    item.isSaved = true;
                }
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        }
    }
}
</script>