var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"bladeName":"customer-orders-import","canDelete":"","canRefresh":false,"canSearch":false,"canSearchLocal":"","dependantBlades":[{ bladeName: 'customer-order' }],"flexColumn":"","headers":[
        { text: 'INV#', value: 'invoiceNumber', subtitle: 2, searchable: true },
        { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', subtitle: 1, prefix: 'Due: ' },
        { text: 'Customer', value: 'supplyAgreement', display: true, searchable: true },
        { text: 'Destination', value: 'location', textFilter: 'toLocationLine' }],"hide-footer":false,"items":_vm.orders,"loading":_vm.isLoading,"searchProps":['invoiceNumber', 'contactName'],"showFilters":false,"title":"Import Customer Orders"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('BT-File-Select',{attrs:{"text":"Import 1.0 CSV","title":"Import CSV from BlitzIt 1.0"},on:{"fileSelected":_vm.importCSV}}),_c('BT-Snack',{model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}}),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":_vm.saveAllOrders}},[_vm._v(" Save All ")])]},proxy:true},{key:"supplyAgreement",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.supplyAgreement != null ? item.supplyAgreement.buyer.companyName : 'Unknown')+" ")]}},{key:"itemActions",fn:function(ref){
        var item = ref.item;
        var items = ref.items;
return [(!item.isSaved)?_c('v-btn',{attrs:{"icon":"","loading":item.loadingCount > 0,"small":"","title":"Save"},on:{"click":function($event){$event.stopPropagation();return _vm.saveCustomerOrder(item, items)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")])],1):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }